import React from 'react'
import styled from '@emotion/styled'
import Helmet from '../components/Helmet'

const PageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  textAlign: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'flex-start',
  height: '100vh',
  width: '100vw'
})

const ContentWrapper = styled.div({
  cursor: 'none',
  maxWidth: '66rem'
})

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Helmet />
      <PageWrapper>
        <ContentWrapper>
          <img src="https://ucarecdn.com/1b6d3a05-95dd-466c-ad0d-355395f4e760/-/stretch/off/-/resize/1000x/Logo_Reto-Schmid.jpg" width="100%" height="auto"/>
          <p>That page can not be found</p>
        </ContentWrapper>
      </PageWrapper>
    </React.Fragment>
  )
}

export default NotFoundPage
